import de from "element-ui/src/locale/lang/de";

export default {
    formatTime(timestamp){
        const format=str=>{
            if (str<10){
                return "0"+str
            }
            return str;
        }
        let date = new Date(parseInt(timestamp));
        return `${date.getFullYear()}年${date.getMonth()+1}月${date.getDate()}日 ${format(date.getHours())}:${format(date.getMinutes())}:${format(date.getSeconds())}`
    },
    formatDate(timestamp){
        let date = new Date(parseInt(timestamp));
        return `${date.getMonth()+1}月${date.getDate()}日`
    },
    parseDeviceType(head){
        let deviceType="未知设备"
        if (!head){
            return deviceType;
        }
        head=head+""
        if(head.toLowerCase().indexOf("wechat")>-1){
            deviceType="微信";
        }else if (head.toLowerCase().indexOf("android")>-1){
            deviceType="安卓";
        }else if (head.toLowerCase().indexOf("iphone")>-1){
            deviceType="苹果";
        }else if (head.toLowerCase().indexOf("mac os")>-1){
            deviceType="MAC";
        }else if (head.toLowerCase().indexOf("windows")>-1){
            deviceType="Windows";
        }
        return deviceType;
    },
    html2Escape(strHtml) {
        return strHtml.replace(/[<>&"]/g, function(c) {
            return {
                '<': '&lt;',
                '>': '&gt;',
                '&': '&amp;',
                '"': '&quot;'
            } [c];
        });
    },
}